<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-08-09 15:55:21
-->
<template>
	<div class="activityDetail" v-loading="loading">
		<common-title :title="'活动详情'" />
		<div class="activityDetail-content">
			<!-- 活动详情 -->
			<div class="">
				<div class="activityDetail-step">
					<div class="activityDetail-step-top">
						<span :class="{'step-circle-green': activityDetail.activityStatus >= 1}"
							class="activityDetail-step-circle">1</span>
						<span :class="{'step-line-green': activityDetail.activityStatus >= 2}"
							class="activityDetail-step-line"></span>
						<span :class="{'step-circle-green': activityDetail.activityStatus >= 2}"
							class="activityDetail-step-circle">2</span>
						<span :class="{'step-line-green': activityDetail.activityStatus >= 3}"
							class="activityDetail-step-line"></span>
						<span :class="{'step-circle-green': activityDetail.activityStatus >= 3}"
							class="activityDetail-step-circle">3</span>
					</div>
					<div class="activityDetail-step-bottom">
						<span
							:class="{'step-bottom-green': activityDetail.activityStatus > 1}">{{activityDetail.activityStatus == 0?"未通过":activityDetail.activityStatus == 1?"审核中":"审核通过"}}</span>
						<span v-if="activityDetail.activityStatus > 1"
							:class="{'step-bottom-orange': activityDetail.workingStatus == 1,'step-bottom-red': activityDetail.workingStatus == 2,'step-bottom-black': activityDetail.workingStatus == 3}">{{activityDetail.workingStatus == 1 ?"工时表待提交":activityDetail.workingStatus == 2 ?"工时表超时未提交":"工时表已提交"}}</span>
						<span v-else>进行中</span>
						<span :class="{'step-bottom-black': activityDetail.activityStatus >= 3}">已完成</span>
					</div>
				</div>
				<div class="common-table">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动ID</span>
							<el-input readonly="readonly" v-model="activityDetail.id" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">共建基地名称</span>
							<span
								class="common-table-text">{{activityDetail.activityType == 1 ? '非共建基地活动': activityDetail.comBaseName}}</span>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动名称</span>
							<!-- <el-input readonly="readonly" v-model="activityDetail.activityName" placeholder="请输入"
								class="common-table-val" /> -->
							<div class="common-table-text">
								<span>{{activityDetail.activityName}}</span><img
									v-if="activityDetail.activityType == '1'" class="common-table-icon"
									src="@/assets/images/common/A.png" alt="">
								<img v-if="activityDetail.activityType == '3'" class="common-table-icon"
									src="@/assets/images/common/B.png" alt="">
							</div>
						</div>
						<div class="common-table-col">
							<span class="common-table-label">组织方</span>
							<el-input readonly="readonly" v-model="activityDetail.organizer" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
					<div v-if="activityDetail.starTime&&activityDetail.endTime" class="">
						<div class="common-table-row">
							<div class="common-table-col">
								<span class="common-table-label">活动时间</span>
								<div class="common-table-text">
									<div class="">
										<span class="common-table-date">{{dateFormat(activityDetail.starTime)}} 至
											{{dateFormat(activityDetail.endTime)}}</span>
									</div>
								</div>
							</div>
							<div class="common-table-col">
								<span class="common-table-label">活动地址</span>
								<div class="common-table-text">{{activityDetail.address}}</div>
							</div>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动详情</span>
							<!-- <div>{{activityDetail.activityDetails}}</div> -->
							<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}"
								v-model="activityDetail.activityDetails" @focus="editorFocus($event)">
							</quill-editor>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">审核信息</span>
							<div style="display: flex;flex-direction: column;" class="common-table-text">
								<div style="text-align: left;width: 100%;">是否通过:
									<span
										:style="{'color': activityDetail.activityStatus > 1 ?'#1CABB9':''}">{{activityDetail.activityStatus == 0?'审核不通过':activityDetail.activityStatus == 1?'待审核':'审核通过'}}</span>
									<span class="apply-people">审核人：</span>
									<span>{{activityDetail.reviewer}}</span>
								</div>
								<div style="text-align: left;width: 100%;">审核意见: {{activityDetail.auditOpinion}}</div>
								<!-- <div v-if="activityDetail.deduction.includes('其他原因')"
									style="text-align: left;width: 100%;">扣分选项:
									<span>其他原因：{{activityDetail.reasonsDetails}}</span>
								</div> -->
								<div v-if="activityDetail.hasOther"
									style="text-align: left;width: 100%;">扣分选项:
									<span>其他原因：{{activityDetail.reasonsDetails}}</span>
								</div>
								<div v-else style="text-align: left;width: 100%;">扣分选项: <span
										v-for="(item, index) in activityDetail.deduction" :key="index">{{item}}, </span>
								</div>
							</div>
						</div>
					</div>
					<div class="common-table-row manhour-confirm">
						<div class="common-table-col">
							<span class="common-table-label">工时认定</span>
							<div class="common-row-time" style="border-top: 0;">
								<div class="row-time-val">
									<div class="time-val-item">
										<span class="val-item-label">核定活动人数上限</span>
										<el-input readonly="readonly" v-model="activityDetail.maxNumPeople"
											class="val-item-ipt" placeholder="请输入" />
									</div>
									<div class="time-val-item">
										<span class="val-item-label">核定单人工时数上限</span>
										<el-input readonly="readonly" v-model="activityDetail.maxOneWorkingHours"
											class="val-item-ipt" placeholder="请输入" />
									</div>
									<div class="time-val-item">
										<span class="val-item-label">核定活动总工时数上限</span>
										<el-input readonly="readonly" v-model="activityDetail.maxWorkingHours"
											class="val-item-ipt" placeholder="请输入" />
									</div>
								</div>
								<div class="row-time-mark">
									<span class="time-mark-label">注：</span>
									<div class="time-mark-content">
										<span>核定活动人数上限指该活动的可参加的总人数的上限。（限整数）</span>
										<span>核定核定单人工时数上限指该活动单人可获得的工时数上限。（限整数/一位小数）</span>
										<span>核定活动总工时数上限指该活动的最终生成工时表的所有志愿者工时数之和。（限整数/一位小数）</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 工时表 -->
			<div class="">
				<div class="activityDetail-title">
					<span>工时表</span>
					<el-button v-if="activityDetail.workingHoursList&&activityDetail.workingHoursList.length > 0"
						@click="downLoadTimeSheet">导出工时表</el-button>
				</div>
				<div v-if="activityDetail.workingHoursList&&activityDetail.workingHoursList.length > 0"
					class="activityDetail-table">
					<el-table style="width: 100%" :data="activityDetail.workingHoursList" border>
						<el-table-column prop="regNum" label="注册号">
						</el-table-column>
						<el-table-column prop="studentId" label="学号">
						</el-table-column>
						<el-table-column prop="name" label="姓名">
						</el-table-column>
						<el-table-column prop="gender" label="性别" width="60px">
							<template slot-scope="scope">
								<span>{{scope.row.gender == true?"男":"女"}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="collegeName" label="学院班级">
							<template slot-scope="scope">
								<span>{{scope.row.collegeName}}{{scope.row.faculty}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="workingHours" label="工时数" width="100px">
						</el-table-column>
						<el-table-column prop="action" label="学生反馈" width="400px">
							<template slot-scope="scope">
								<div class="" v-if="scope.row.feedbacks&&scope.row.feedbacks.length > 0">
									<div v-for="(item, index) in scope.row.feedbacks" :key="index"
										class="table-action-feedback">
										<!-- <div class="action-feedback-label">
											反馈内容
										</div> -->
										<div class="action-feedback-text">
											{{item.opinion?item.opinion:'无'}}
										</div>
										<!-- <div class="action-feedback-label">
											反馈图片
										</div> -->
										<div class="action-feedback-imgs">
											<el-image v-for="(citem, cindex) in item.opinionImg" :key="cindex"
												fit="cover" class="feedback-imgs" :src="citem"
												:preview-src-list="[citem]">
											</el-image>
										</div>
									</div>
								</div>
								<div v-else class="action-feedback-empty">
									<span>暂无学生反馈~</span>
								</div>
								<!-- <div class="table-action">
									<el-popover placement="top" width="400" trigger="click">
										<div class="" v-if="activityFedback.length > 0&&!isEmptyFedback">
											<div v-for="(item, index) in activityFedback" :key="index"
												class="table-action-feedback">
												<div class="action-feedback-label">
													反馈内容
												</div>
												<div class="action-feedback-text">
													{{item.opinion?item.opinion:'无'}}
												</div>
												<div class="action-feedback-label">
													反馈图片
												</div>
												<div class="action-feedback-imgs">
													<el-image v-for="(citem, cindex) in item.opinionImg" :key="cindex"
														fit="cover" class="feedback-imgs" :src="citem"
														:preview-src-list="[citem]">
													</el-image>
												</div>
											</div>
										</div>
										<div v-else class="action-feedback-empty">
											<img src="@/assets/images/common/empty.png" alt="">
											<span>暂无学生反馈~</span>
										</div>
										<span slot="reference" @click="actionFn('detail', scope)">查看</span>
									</el-popover>
								</div> -->
							</template>
						</el-table-column>
						<el-table-column prop="action" label="操作" fixed="right" width="80px">
							<template slot-scope="scope">
								<div class="table-action">
									<span @click="actionFn('submit', scope)">私聊</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div v-else class="activityDetail-upload">
					<div v-if="activityDetail.activityStatus != 0" class="activityDetail-upload-top">
						<span @click="manhourFile('upload')" class="upload-top-btn">选择文件</span>
						<span class="upload-top-text">工时表文件.xlsx</span>
					</div>
					<div v-if="activityDetail.activityStatus != 0" @click="manhourFile('download')"
						class="activityDetail-upload-bottom">
						<img src="@/assets/images/common/xls.png" alt="">
						<span>点击下载工时表模板</span>
					</div>
					<div v-if="upLoadErrorMsg" class="activityDetail-upload-fail">
						<div class="upload-fail-top">
							<span style="color: #DF4635;">上传失败！</span>
							<span>①确认工时表模板是否正确，可在上传表格处下载。</span>
							<span>②工时表F列无任何错误提示显示。</span>
							<span>③确认是否使用《Microsoft Office Excel 2007》及以上版本制作，WPS office和盗版均有可能导致失败。</span>
							<span>④如始终无法上传，可通过活动招募详情导入志愿者工时。（进入活动招募详情，点击“生成工时表”，关联此活动，选择志愿者并输入工时）。</span>
						</div>
						<div class="upload-fail-bottom">
							<span>错误信息：</span>
							<span>{{upLoadErrorMsg}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="dialogVisible" width="520px">
				<div slot="title" class="dialog-header">
					<span>{{dialogTitle}}</span>
					<img @click="closeDialog" src="@/assets/images/common/close.png">
				</div>
				<el-form  :model="messegsForm" :rules="messegsRules" ref="messegsForm" label-width="100px"
					class="">
					<el-form-item class="recruitDetail-dia-formitem" label="通知标题:" prop="title">
						<div class="recruitDetail-dia-ipt">
							<el-input v-model="messegsForm.title" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
					<el-form-item class="recruitDetail-dia-formitem" label="通知内容:" prop="content">
						<div class="recruitDetail-dia-textarea">
							<el-input type="textarea" v-model="messegsForm.content" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button @click="submitDialog" type="primary">确 定</el-button>
				</span>
			</el-dialog>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	</div>
</template>

<script>
	import {
		getActivityDetail,
		getManhourModal,
		uploadManhourFile,
		fedbackActivity,
		exportActivityTimeSheet,
	} from "@/api/activity";
	import {
		chatPrivate,
	} from "@/api/recruit";
	import {
		getEnumKeyList
	} from "@/api/index.js";
	export default {
		data() {
			return {
				loading:false,
				activityDetail: {},
				activityId: undefined,
				upLoadUrl: "",
				deductionList: [],
				upLoadErrorMsg: undefined,
				activityFedback: [],
				isEmptyFedback: false,
				editorOption: {
					placeholder: "暂无内容......",
				},
				dialogVisible: false,
				dialogTitle: "",
				messegsRules: {
					title: [{
						required: true,
						message: "请输入通知标题",
						trigger: "blur"
					}],
					content: [{
						required: true,
						message: "请输入通知内容",
						trigger: "blur"
					}]
				},
				messegsForm: {},
				volunteerId:''
			};
		},
		async created() {
			await this.getDeductionList()
			this.activityId = this.$route.query.activityId
			this.activityDetailFn()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			hasOtherFn(intArr) {
				if (intArr && intArr.length > 0) {
					if (intArr.includes('其他原因') || intArr.includes('其他')) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["messegsForm"].validate(valid => {
					if (valid) {
						let params = {
							...this.messegsForm,
							category: "私聊",
							sender: this.activityDetail.id,
							receiver: this.volunteerId,
						}
						chatPrivate(params).then(res => {
							if (res.code == 0) {
								this.$message({
									type: 'success',
									message: '发送成功!'
								});
								this.dialogVisible = false
							}
						})
					}
				})
			},
			downLoadTimeSheet() {
				let params = {
					id: this.activityId,
				}
				exportActivityTimeSheet(params).then(res => {
					console.log("downLoadApply", res)
					const blob = new Blob([res], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					})
					// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
					if ('download' in document.createElement('a')) {
						// 非IE下载
						const elink = document.createElement('a')
						elink.download = "工时表.xlsx"
						elink.style.display = 'none'
						elink.href = URL.createObjectURL(blob)
						document.body.appendChild(elink)
						elink.click()
						URL.revokeObjectURL(elink.href) // 释放URL 对象
						document.body.removeChild(elink)
					} else {
						// IE10+下载
						navigator.msSaveBlob(blob, "工时表.xlsx")
					}
				})
			},
			getActivityFedback(activityId, volunteerId) {
				this.activityFedback = []
				this.isEmptyFedback = false
				fedbackActivity({
					activityId: activityId,
					volunteerId: volunteerId
				}).then((res) => {
					if (res.code == 0) {
						if (res.data.length > 0) {
							this.isEmptyFedback = res.data.some(item => !item.opinion && !item.opinionImg)
							res.data.forEach(item => {
								if (item.opinionImg) {
									item.opinionImg = item.opinionImg.split(",")
								}
							})
							console.log("isEmptyFedback", this.isEmptyFedback)
							this.activityFedback = res.data
						}
					}
				})
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.getActivityFedback(scope.row.activityId, scope.row.volunteerId)
						break;
					case "submit":
						this.dialogTitle = `私聊消息发送（${scope.row.name}）`
						this.volunteerId = scope.row.volunteerId
						this.dialogVisible = true
						break;
				}
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			deductionMap(intVal) {
				let mapArr = this.deductionList.filter(item => item.key == intVal)
				return mapArr[0].value
			},
			getDeductionList() {
				return new Promise((resove, reject) => {
					getEnumKeyList({
						enumKey: "AUDIT_REASONS"
					}).then((res) => {
						if (res.code == 0) {
							this.deductionList = res.data
							resove()
						} else {
							reject()
						}
					})
				})
			},
			manhourFile(type) {
				switch (type) {
					case "upload":
						document.querySelector(".uploadImage input").click();
						break;
					case "download":
						// getManhourModal().then(res => {})
						// window.open("http://upload.52xiangling.cn/upload/file/2208/working_hours.xlsx")
						window.open("https://www.api.volunteer.zjut.edu.cn/upload/upload/file/2303/93e11b37e5904a009bcaaabfd5ee36cf.xlsx")
						break;
				}
			},
			beforeUpload(file) {
				console.log("beforeUpload", file);
				this.loading = true
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				if (fileType != "xlsx") {
					this.$message({
						message: "上传文件只能是xlsx格式!",
						type: "warning"
					});
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				this.upLoadErrorMsg = undefined
				const formData = new FormData();
				formData.append('file', data.file);
				formData.append('activityId', this.activityId);
				uploadManhourFile(formData).then((res) => {
					console.log("上传成功", res)
					if (res.code == 0) {
						this.loading = false
						this.upLoadErrorMsg = undefined
						this.$message({
							message: "工时表上传成功!",
							type: "success"
						});
						// this.$router.go(-1)
						this.activityDetailFn()
					} else {
						this.loading = false
						this.$message({
							message: res.msg,
							type: "error"
						});
						this.upLoadErrorMsg = res.msg
					}
				}).catch((error) => {
					console.log("error", error.response)
					this.loading = false
					this.$message({
						message: error.response.data.msg,
						type: "error"
					});
					this.upLoadErrorMsg = error.response.data.msg
				})
			},
			editorFocus(e) {
				e.enable(false);
			},
			// 获取活动详情
			activityDetailFn() {
				getActivityDetail(this.activityId, {
					id: this.activityId
				}).then((res) => {
					if (res.code == 0) {
						if (res.data.deduction) {
							res.data.deduction = res.data.deduction.split(",")
							let hasOther = this.hasOtherFn(res.data.deduction)
							this.$set(res.data, "hasOther", hasOther)
						} else {
							res.data.deduction = []
							this.$set(res.data, "hasOther", false)
						}
						if(res.data.workingHoursList){
							res.data.workingHoursList.forEach(el=>{
								if(el.feedbacks&&el.feedbacks.length>0){
									el.feedbacks.forEach(item=>{
										if (item.opinionImg) {
											item.opinionImg = item.opinionImg.split(",")
										}
									})
								}
							})
						}
						this.activityDetail = res.data
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
.apply-people{
	display: inline-block;
	margin-left:40px;
}
/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 400px;
		border: 0;
	}

	/deep/.recruitDetail-dia-textarea .el-textarea__inner {
		height: 120px !important;
		resize: none;
		border: 0;
	}

.recruitDetail-dia-formitem {
		margin: 20px 20px 0 0;

		.recruitDetail-dia-ipt {
			border: 1px solid #DCDFE6;
			border-radius: 4px;
		}

		.recruitDetail-dia-textarea {
			border: 1px solid #DCDFE6;
			border-radius: 4px;
		}
	}
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.manhour-confirm .el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 400px;
		border: 0;
	}

	.common-table-icon {
		width: 28px;
		height: 15px;
		margin-left: 8px;
	}

	.common-table-date {
		margin-right: 8px;
	}

	.action-feedback-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			width: 150px;
			height: 150px;
		}
	}


	.table-action-feedback {
		display: flex;
		flex-direction: column;


		.action-feedback-label {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: bold;
			line-height: 20px;
			color: #303133;
			margin: 8px 0;
		}

		.action-feedback-text {
			text-align: left;
			margin-bottom:10px;
			font-size: 12px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 18px;
			color: #909399;
		}

		.action-feedback-imgs {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			.feedback-imgs {
				width: 48px;
				height: 48px;
				border-radius: 2px;
				margin: 0 18px 8px 0;
				cursor: pointer;
			}
		}
	}

	.activityDetail {
		.activityDetail-content {
			padding: 0 20px 20px 20px;

			.activityDetail-step {
				display: flex;
				flex-direction: column;
				padding: 0 75px;
				margin: 20px 0;

				.activityDetail-step-top {
					display: flex;
					align-items: center;
					padding: 0 5px;

					.activityDetail-step-circle {
						width: 32px;
						height: 32px;
						line-height: 32px;
						background: #FFFFFF;
						border: 1px solid #B4BCC6;
						border-radius: 50%;
						text-align: center;
						font-size: 16px;
						font-family: PingFang SC;
						font-weight: bold;
						color: #B4BCC6;
					}

					.step-circle-green {
						border-color: $theme-color !important;
						color: $theme-color !important;
					}

					.step-circle-red {
						border-color: #E63757 !important;
						color: #E63757 !important;
					}

					.step-line-green {
						background-color: $theme-color !important;
					}

					.step-line-red {
						background-color: #E63757 !important;
					}

					.activityDetail-step-line {
						flex: 1;
						height: 2px;
						background: #DCDFE6;
					}
				}

				.activityDetail-step-bottom {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 10px;

					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #B4BCC6;
					}

					.step-bottom-black {
						color: #303133;
					}

					.step-bottom-green {
						color: $theme-color !important;
					}

					.step-bottom-orange {
						color: #f5803e;
					}

					.step-bottom-red {
						color: #e63757;
					}
				}
			}

			.activityDetail-title {
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: bold;
				line-height: 22px;
				color: #1C2233;
				margin: 20px 0 10px 0;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.activityDetail-title-btn {}
			}

			.activityDetail-upload {
				display: flex;
				flex-direction: column;

				.activityDetail-upload-top {
					display: flex;
					align-items: center;

					.upload-top-btn {
						width: 80px;
						height: 28px;
						background: #F6F7F9;
						border: 1px solid #DCDFE6;
						border-radius: 2px;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						color: #909399;
						margin-right: 8px;
						cursor: pointer;
					}

					.upload-top-text {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #1C2233;
					}
				}

				.activityDetail-upload-bottom {
					display: flex;
					align-items: center;
					cursor: pointer;
					margin-top: 10px;

					img {
						width: 16px;
						height: 16px;
						margin-right: 8px;
					}

					span {
						font-size: 12px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 17px;
						color: #606266;
					}
				}
			}
		}
	}

	.activityDetail-upload-fail {
		display: flex;
		flex-direction: column;

		.upload-fail-top,
		.upload-fail-bottom {
			display: flex;
			flex-direction: column;
			margin-top: 16px;

			span {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 20px;
				color: #303133;
			}
		}
	}
</style>
